var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('form', {
    attrs: {
      "id": "tranMgr",
      "name": "tranMgr",
      "method": "post"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentMethod,
      expression: "paymentMethod"
    }],
    attrs: {
      "type": "hidden",
      "name": "PayMethod"
    },
    domProps: {
      "value": _vm.paymentMethod
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.paymentMethod = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "GoodsCnt",
      "value": "1"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderName,
      expression: "orderName"
    }],
    attrs: {
      "type": "hidden",
      "name": "GoodsName"
    },
    domProps: {
      "value": _vm.orderName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentAmount,
      expression: "paymentAmount"
    }],
    attrs: {
      "type": "hidden",
      "name": "Amt"
    },
    domProps: {
      "value": _vm.paymentAmount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.paymentAmount = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderNo,
      expression: "orderNo"
    }],
    attrs: {
      "type": "hidden",
      "name": "Moid"
    },
    domProps: {
      "value": _vm.orderNo
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderNo = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.MID,
      expression: "MID"
    }],
    attrs: {
      "type": "hidden",
      "name": "Mid"
    },
    domProps: {
      "value": _vm.MID
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.MID = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.returnUrl,
      expression: "returnUrl"
    }],
    staticClass: "input",
    attrs: {
      "type": "hidden",
      "name": "ReturnUrl"
    },
    domProps: {
      "value": _vm.returnUrl
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.returnUrl = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.returnUrl,
      expression: "returnUrl"
    }],
    staticClass: "input",
    attrs: {
      "type": "hidden",
      "name": "StopUrl"
    },
    domProps: {
      "value": _vm.returnUrl
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.returnUrl = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerName,
      expression: "buyerName"
    }],
    attrs: {
      "type": "hidden",
      "name": "BuyerName"
    },
    domProps: {
      "value": _vm.buyerName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerPhone,
      expression: "buyerPhone"
    }],
    attrs: {
      "type": "hidden",
      "name": "BuyerTel"
    },
    domProps: {
      "value": _vm.buyerPhone
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerPhone = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerEmail,
      expression: "buyerEmail"
    }],
    attrs: {
      "type": "hidden",
      "name": "BuyerEmail"
    },
    domProps: {
      "value": _vm.buyerEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerEmail = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "VbankExpDate"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.encryptData,
      expression: "encryptData"
    }],
    attrs: {
      "type": "hidden",
      "name": "EncryptData"
    },
    domProps: {
      "value": _vm.encryptData
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.encryptData = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "name": "GoodsCl",
      "value": "1"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.ediDate,
      expression: "ediDate"
    }],
    attrs: {
      "type": "hidden",
      "name": "EdiDate"
    },
    domProps: {
      "value": _vm.ediDate
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.ediDate = $event.target.value;
      }
    }
  })]), _c('form', {
    attrs: {
      "id": "approvalForm",
      "name": "approvalForm",
      "action": _vm.returnUrl,
      "method": "post"
    }
  }, [_c('input', {
    attrs: {
      "type": "hidden",
      "id": "Tid",
      "name": "Tid"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "id": "TrAuthKey",
      "name": "TrAuthKey"
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }