
<template>
    <div>
        <form id="tranMgr" name="tranMgr" method="post">
            <!-- 각 값들을 가맹점에 맞게 설정해 주세요. -->
            <input type="hidden" name="PayMethod" v-model="paymentMethod"/>
            <input type="hidden" name="GoodsCnt" value="1"/>
            <input type="hidden" name="GoodsName" v-model="orderName"/>
            <input type="hidden" name="Amt" v-model="paymentAmount"/>
            <input type="hidden" name="Moid" v-model="orderNo"/>
            <input type="hidden" name="Mid" v-model="MID"/>
            <input type="hidden" name="ReturnUrl" class="input" v-model="returnUrl">
            <input type="hidden" name="StopUrl" class="input" v-model="returnUrl"/>
            <input type="hidden" name="BuyerName" v-model="buyerName"/>
            <input type="hidden" name="BuyerTel" v-model="buyerPhone"/>
            <input type="hidden" name="BuyerEmail" v-model="buyerEmail"/>
            <input type="hidden" name="VbankExpDate"/>
            <input type="hidden" name="EncryptData" v-model="encryptData"/>
            <input type="hidden" name="GoodsCl" value="1"/>
            <input type="hidden" name="EdiDate" v-model= "ediDate"/>
        </form>
        <form id="approvalForm" name="approvalForm" :action="returnUrl" method="post">
            <input type="hidden" id="Tid" name="Tid"/>
            <input type="hidden" id="TrAuthKey" name="TrAuthKey"/>
        </form>
    </div>
</template>
<!-- <script id="smartropay"></script> -->
<script>
import api from "@/api";
export default {
    data(){
        return {
            // 결제정보
            serviceId: "",
            orderNo: this.$route.query.orderNo,
            orderName: this.$route.query.orderName,
            orderCode: "G00000",
            orderDate: this.$route.query.orderDate,
            paymentAmount: this.$route.query.paymentAmount,
            paymentMethod: this.$route.query.paymentMethod,
            buyerUsername: "",
            buyerName: this.$route.query.buyerName,
            buyerPhone: this.$route.query.buyerPhone,
            buyerEmail: this.$route.query.buyerEmail,
            MID: "",
            encryptData: "",
            ediDate: "",

            telecom: this.$route.query.telecom,
            authNumber: this.$route.query.authNumber,

            checkSum: null,
        };
    },
    computed: {
        returnUrl(){
            return window.location.origin + "/api/plugins/smartro/response";
        },
    },
    methods: {
        async init() {
            const scriptElement = document.getElementById('smartropay');
            let paymentOption = { FormId: "tranMgr" }

            let { MODE, PC_GATEWAY, MOBILE_GATEWAY } = await api.plugins.smartro.config.get();

            if (this.isMobile()) {
                scriptElement.src = `${MOBILE_GATEWAY}?version=${this.$dayjs().format("YYYYMMDD")}`;
            } else {
                scriptElement.src = `${PC_GATEWAY}?version=${this.$dayjs().format("YYYYMMDD")}`;
                paymentOption.Callback = this.smartroCallback();
            }
            scriptElement.addEventListener("load", async () => {
                let { MID, ediDate, encryptData } = await api.plugins.smartro.generate({
                    paymentAmount: this.paymentAmount
                });
    
                this.MID = MID;
                this.ediDate = ediDate;
                this.encryptData = encryptData
    
                await smartropay.init({ mode: MODE });
                await smartropay.payment(paymentOption);
            });
        },
        isMobile() {
            return navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1;
        },
        smartroCallback() {
            return function(res) {
                var approvalForm = document.getElementById("approvalForm");
                approvalForm.Tid.value = res.Tid;
                approvalForm.TrAuthKey.value = res.TrAuthKey;
                approvalForm.submit();
            }
        }
    },
    mounted(){
        window.smartroComplete = (payload) => {
            window?.parent?.smartroComplete(payload);
            window?.opener?.smartroComplete(payload);
        };
        this.init();
    },
}
</script>
